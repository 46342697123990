<template>
    <div class="home">
        <header class="print-none">
            <HeaderNavbar />
        </header>
        <main class="main print-none">
            <div class="container">
                <div class="container-flex">
                    <div class="import-wrapper">
                        <router-link to="/import" title="Go to Import"
                            ><a-button type="primary" size="large"
                                ><a-icon type="export" /> Import</a-button
                            ></router-link
                        >
                    </div>
                    <div class="search-wrapper">
                        <a-input
                            placeholder="Nhập thông tin cần tìm..."
                            size="large"
                            allowClear
                            @change="onSearchChange"
                        >
                            <a-icon slot="prefix" type="search" />
                        </a-input>
                    </div>
                    <div class="picker-wrapper">
                        <a-range-picker size="large" @change="onRangeChange"></a-range-picker>
                    </div>
                    <div class="download-wrapper">
                        <a-dropdown
                            :trigger="['click']"
                            :disabled="!fetchParams.start"
                            title="Download Reports"
                        >
                            <a-button type="primary" size="large"
                                ><a-icon type="download"
                            /></a-button>
                            <a-menu slot="overlay" @click="handleDownloadMenu">
                                <a-menu-item key="xls">
                                    <a-icon type="file-excel" />XLS File</a-menu-item
                                >
                                <a-menu-item key="xml">
                                    <a-icon type="file-text" />XML File</a-menu-item
                                >
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
                <div class="container-flex receipt-table">
                    <a-table
                        :row-selection="rowSelection"
                        :columns="tableColumns"
                        :data-source="tableData"
                        :loading="tableLoading"
                        :pagination="false"
                        :scroll="{ x: 1900 }"
                        nge="onTableChange"
                    >
                        <a
                            slot="ID"
                            slot-scope="ID, item"
                            @click="modalSingleShow(item)"
                            :style="{ color: item.color }"
                            >{{ item.numero }}</a
                        >
                        <a-tag slot="status" slot-scope="status, item" :color="item.color">
                            {{ status.toUpperCase() }}
                        </a-tag>
                    </a-table>
                </div>
                <div class="container-flex">
                    <div class="export-wrapper">
                        <a-button
                            type="primary"
                            size="large"
                            @click="handleExportButton"
                            :disabled="!hasSelected"
                        >
                            <a-icon type="printer" /> Print
                        </a-button>
                        <span
                            ><template v-if="hasSelected"
                                >&nbsp; {{ `Selected ${selectedData.length} items` }}</template
                            ></span
                        >
                    </div>
                    <div class="paging-wrapper">
                        <a-pagination
                            show-size-changer
                            :total="tableTotal"
                            :show-total="onShowTotal"
                            @change="onPageChange"
                            @showSizeChange="onSizeChange"
                        />
                    </div>
                </div>
            </div>
            <a-modal
                v-model="modalReceiptSingle"
                title="Thông tin chi tiết"
                centered
                :footer="null"
                :width="720"
            >
                <ReceiptEditor
                    :receiptID="clickedReceiptID"
                    :rowKey="clickedRowKey"
                    @trashed="trashedSingle"
                    @actived="activedSingle"
                    @updated="updatedSingle"
                />
            </a-modal>
        </main>
        <div class="media-none print-block">
            <div>
                <div v-for="selectedRow in selectedData" v-bind:key="selectedRow.ID">
                    <TaxCertificate v-bind:original="1" v-bind:receipt="selectedRow" />
                </div>
            </div>
            <div>
                <div v-for="selectedRow in selectedData" v-bind:key="selectedRow.ID">
                    <TaxCertificate v-bind:original="2" v-bind:receipt="selectedRow" />
                </div>
            </div>
            <div>
                <div v-for="selectedRow in selectedData" v-bind:key="selectedRow.ID">
                    <ConfirmLetter v-bind:receipt="selectedRow" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderNavbar from '@/components/HeaderNavbar.vue';
import ReceiptEditor from '@/components/ReceiptEditor.vue';
import TaxCertificate from '@/components/TaxCertificate.vue';
import ConfirmLetter from '@/components/ConfirmLetter.vue';

function IDFormat(value, width) {
    return new Array(+width + 1 - (value + '').length).join('0') + value;
}

const tableColumns = [
    {
        title: 'No.',
        dataIndex: 'ID',
        sorter: true,
        defaultSortOrder: 'descend',
        scopedSlots: {
            customRender: 'ID',
        },
        fixed: true,
        width: 120,
    },
    {
        title: 'Code',
        dataIndex: 'code',
        sorter: true,
        align: 'left',
        width: 120,
        fixed: true,
    },
    {
        title: 'Họ và tên',
        dataIndex: 'fullname',
        sorter: true,
        width: 180,
    },
    {
        title: 'Mã số thuế',
        dataIndex: 'taxcode',
        sorter: true,
    },
    {
        title: 'Số CMND/CCCD/Hộ chiếu',
        dataIndex: 'id_number',
        sorter: true,
    },
    {
        title: 'Khoản đóng Bảo hiểm bắt buộc',
        dataIndex: 'deductible_ins_prem',
        sorter: true,
    },
    {
        title: 'Thời điểm trả thu nhập',
        dataIndex: 'payment_time',
        sorter: true,
    },
    {
        title: 'Tổng thu nhập',
        dataIndex: 'income_gross',
        sorter: true,
    },
    {
        title: 'Tổng thu nhập tính thuế',
        dataIndex: 'total_tax_calculation_income',
        sorter: true,
    },
    {
        title: 'TNCN đã khấu trừ',
        dataIndex: 'income_tax',
        sorter: true,
    },
    {
        title: 'Bảo hiểm được trừ',
        dataIndex: 'insurances',
        sorter: true,
    },
    {
        title: 'Tiền thuê nhà',
        dataIndex: 'house_rental',
        sorter: true,
    },
    {
        title: 'Ngày nhập',
        dataIndex: 'created',
        sorter: true,
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        scopedSlots: {
            customRender: 'status',
        },
        filters: [
            { text: 'Active', value: 'Active' },
            { text: 'Exported', value: 'Exported' },
            { text: 'Canceled', value: 'Canceled' },
        ],
        filterMultiple: false,
        fixed: 'right',
    },
];

export default {
    data: function () {
        return {
            fetchParams: {},
            modalReceiptSingle: false,
            tableColumns,
            tableTotal: 0,
            tableData: [],
            tableLoading: false,
            selectedData: [],
            selectedRowKeys: [],
            clickedReceiptID: 0,
            clickedRowKey: 0,
        };
    },
    created: function () {
        this.fetchParams.ordby = 'ID';
        this.fetchParams.order = 'DESC';

        this.fetchPosts();
    },
    mounted() {
        document.title = 'PIT System - Servier Vietnam';
    },
    computed: {
        hasSelected() {
            return this.selectedData.length > 0;
        },
        rowSelection() {
            return {
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedData) => {
                    this.selectedData = selectedData;
                    this.selectedRowKeys = selectedRowKeys;
                },
                getCheckboxProps: (record) => ({
                    props: {
                        disabled: record.status === 'Canceled',
                    },
                }),
            };
        },
    },
    methods: {
        onSearchChange(e) {
            delete this.fetchParams.page;

            if (e.target.value) {
                this.fetchParams.search = e.target.value;
            } else {
                delete this.fetchParams.search;
            }

            this.fetchPosts();
        },
        onRangeChange(date, dateString) {
            delete this.fetchParams.page;

            if (dateString[0] && dateString[1]) {
                this.fetchParams.start = dateString[0];
                this.fetchParams.end = dateString[1];
            } else {
                delete this.fetchParams.start;
                delete this.fetchParams.end;
            }

            this.fetchPosts();
        },
        onTableChange(pagination, filters, sorter) {
            delete this.fetchParams.page;

            if (filters.status) {
                this.fetchParams.status = filters.status[0];
            } else {
                delete this.fetchParams.status;
            }

            if (sorter.order) {
                this.fetchParams.ordby = sorter.field;

                if (sorter.order === 'descend') {
                    this.fetchParams.order = 'DESC';
                } else {
                    delete this.fetchParams.order;
                }
            } else {
                delete this.fetchParams.ordby;
                delete this.fetchParams.order;
            }

            this.fetchPosts();
        },
        onShowTotal(total, range) {
            return range[0] + '-' + range[1] + ' of ' + total + ' items';
        },
        onPageChange(page) {
            if (page > 1) {
                this.fetchParams.page = page;
            } else {
                delete this.fetchParams.page;
            }

            this.fetchPosts();
        },
        onSizeChange(current, size) {
            this.fetchParams.page = current;
            this.fetchParams.size = size;
            this.fetchPosts();
        },
        modalSingleShow(item) {
            this.clickedRowKey = item.key;
            this.clickedReceiptID = item.ID;
            this.modalReceiptSingle = true;
        },
        trashedSingle() {
            this.modalReceiptSingle = false;

            this.selectedRowKeys = this.selectedRowKeys.filter(
                (item) => item !== this.clickedRowKey,
            );

            this.tableData[this.clickedRowKey].status = 'Canceled';
            this.tableData[this.clickedRowKey].color = 'red';
            this.$message.success('Moved to Trash');
        },
        activedSingle() {
            this.tableData[this.clickedRowKey].status = 'Active';
            this.tableData[this.clickedRowKey].color = 'green';
            this.$message.success('Item restored');
        },
        updatedSingle(single) {
            this.tableData[this.clickedRowKey].code = single.code;
            this.tableData[this.clickedRowKey].fullname = single.fullname;
            this.tableData[this.clickedRowKey].taxcode = single.taxcode;
            this.tableData[this.clickedRowKey].id_number = single.id_number;
            this.tableData[this.clickedRowKey].payment_time = single.payment_time;
            this.tableData[this.clickedRowKey].income_gross = new Intl.NumberFormat('vi').format(
                single.income_gross,
            );
            this.tableData[this.clickedRowKey].income_tax = new Intl.NumberFormat('vi').format(
                single.income_tax,
            );
            this.tableData[this.clickedRowKey].insurances = new Intl.NumberFormat('vi').format(
                single.insurances,
            );
            this.tableData[this.clickedRowKey].house_rental = new Intl.NumberFormat('vi').format(
                single.house_rental,
            );
            this.modalReceiptSingle = false;
        },
        handleExportButton() {
            window.print();

            this.selectedData.forEach((item) => {
                if (item.status === 'Active') {
                    this.$http
                        .put('/posts/' + item.ID, {
                            status: 'Exported',
                        })
                        .then((response) => {
                            if (response.data.success) {
                                item.status = 'Exported';
                                item.color = 'blue';
                            }
                        });
                }
            });
        },
        handleDownloadMenu(e) {
            this.$http
                .get('/exports/' + e.key, {
                    params: {
                        start: this.fetchParams.start,
                        end: this.fetchParams.end,
                    },
                    responseType: 'blob',
                })
                .then(({ data }) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute(
                        'download',
                        'PIT-' + this.fetchParams.start + '-' + this.fetchParams.end + '.' + e.key,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        },
        fetchPosts() {
            this.tableLoading = true;
            this.selectedData = [];
            this.selectedRowKeys = [];

            this.$http
                .get('/posts', {
                    params: this.fetchParams,
                })
                .then((response) => {
                    let formatedData = [];
                    console.log(response.data.items[0]);

                    response.data.items.forEach(function (item, index) {
                        item.key = index;
                        item.numero = IDFormat(item.ID, 7);
                        item.color =
                            item.status === 'Canceled'
                                ? 'red'
                                : item.status === 'Exported'
                                ? 'blue'
                                : 'green';
                        item.income_gross = new Intl.NumberFormat('vi').format(item.income_gross);
                        item.income_tax = new Intl.NumberFormat('vi').format(item.income_tax);
                        item.total_tax_calculation_income = new Intl.NumberFormat('vi').format(
                            item.total_tax_calculation_income,
                        );
                        item.insurances = new Intl.NumberFormat('vi').format(item.insurances);
                        item.house_rental = new Intl.NumberFormat('vi').format(item.house_rental);
                        item.deductible_ins_prem = new Intl.NumberFormat('vi').format(item.deductible_ins_prem);
                        item.created = new Intl.DateTimeFormat('en-CA').format(
                            new Date(item.created),
                        );

                        // Added income_From
                        item.income_from = new Intl.DateTimeFormat('en-CA').format(
                            new Date(item.income_from),
                        );

                        formatedData.push(item);
                    });

                    this.tableData = formatedData;
                    this.tableTotal = response.data.total;
                    this.tableLoading = false;
                });
        },
    },
    components: {
        HeaderNavbar,
        ReceiptEditor,
        TaxCertificate,
        ConfirmLetter,
    },
};
</script>
<style lang="scss">
.import-wrapper,
.export-wrapper {
    margin-right: 1rem;

    img {
        margin-right: 0.5rem;
    }
}
.search-wrapper {
    width: 360px;
    margin-left: auto;
    margin-right: 1rem;
}

.picker-wrapper {
    max-width: 260px;

    .ant-calendar-picker-input {
        border-radius: 4px 0 0 4px;
        border-right: none;
    }
}

.download-wrapper {
    .ant-dropdown-trigger {
        border-radius: 0 4px 4px 0;
    }
}

.receipt-table {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .ant-table-wrapper {
        width: 100%;
    }
}

.media-none {
    display: none;
}

@media print {
    .print-block {
        display: block;
    }

    .print-none {
        display: none;
    }

    .ant-dropdown {
        display: none !important;
    }
}
</style>
