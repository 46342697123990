<template>
    <a-form-model
        :model="formData"
        @submit="handleSubmit"
        v-if="receiptID"
        style="margin-bottom: -14px"
    >
        <a-row :gutter="24">
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <a-form-model-item label="No."
                        ><a-input :value="formatedID" disabled
                    /></a-form-model-item>
                    <a-form-model-item label="Họ và tên"
                        ><a-input v-model="formData.fullname" :disabled="disabledField"
                    /></a-form-model-item>
                    <a-row :gutter="12">
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Mã nhân sự"
                                    ><a-input v-model="formData.code" :disabled="disabledField"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Mã số thuế"
                                    ><a-input v-model="formData.taxcode" :disabled="disabledField"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                    </a-row>
                    <a-form-model-item label="Quốc tịch"
                        ><a-input v-model="formData.nationality" :disabled="disabledField"
                    /></a-form-model-item>
                    <a-form-model-item label="Ngày đến Việt Nam"
                        ><a-date-picker
                            valueFormat="YYYY-MM-DD"
                            v-model="formData.arrival_date"
                            :disabled="disabledField"
                            style="width: 100%"
                    /></a-form-model-item>
                    <a-form-model-item label="Cá nhân cư trú">
                        <a-radio-group v-model="formData.resident">
                            <a-radio value="Yes" :disabled="disabledField">Có</a-radio>
                            <a-radio value="No" :disabled="disabledField">Không</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="Địac chỉ hoặc điện thoại liên hệ"
                        ><a-input
                            v-model="formData.contact_address_phone"
                            :disabled="disabledField"
                    /></a-form-model-item>
                    <a-form-model-item label="Số CMND/ Hộ chiếu/ CCCD"
                        ><a-input v-model="formData.id_number" :disabled="disabledField"
                    /></a-form-model-item>
                    <a-row :gutter="12">
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Ngày cấp"
                                    ><a-date-picker
                                        valueFormat="YYYY-MM-DD"
                                        v-model="formData.id_issued"
                                        :disabled="disabledField"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Nơi cấp"
                                    ><a-input v-model="formData.id_place" :disabled="disabledField"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <a-form-model-item label="Khoản thu nhập"
                        ><a-input v-model="formData.income_type" :disabled="disabledField"
                    /></a-form-model-item>
                    <a-row :gutter="12">
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Thu nhập từ ngày"
                                    ><a-date-picker
                                        valueFormat="YYYY-MM-DD"
                                        v-model="formData.income_from"
                                        :disabled="disabledField"
                                        style="width: 100%"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Đến ngày"
                                    ><a-date-picker
                                        valueFormat="YYYY-MM-DD"
                                        v-model="formData.income_to"
                                        :disabled="disabledField"
                                        style="width: 100%"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                    </a-row>
                    <a-form-model-item label="Tổng thu nhập chịu thuế"
                        ><a-input-number
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            v-model="formData.income_gross"
                            :disabled="disabledField"
                            addon-after="VNĐ"
                            style="width: 100%"
                    /></a-form-model-item>
                    <a-form-model-item label="Số thuế TNCN đã khấu trừ"
                        ><a-input-number
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            v-model="formData.income_tax"
                            :disabled="disabledField"
                            addon-after="VNĐ"
                            style="width: 100%"
                    /></a-form-model-item>
                    <a-form-model-item label="Tổng thu nhập tính thuế"
                        ><a-input-number
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            v-model="formData.total_tax_calculation_income"
                            :disabled="disabledField"
                            addon-after="VNĐ"
                            style="width: 100%"
                    /></a-form-model-item>
                    <a-form-model-item label="Bảo hiểm được trừ"
                        ><a-input-number
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            v-model="formData.insurances"
                            :disabled="disabledField"
                            addon-after="VNĐ"
                            style="width: 100%"
                        />
                    </a-form-model-item>
                    <a-form-model-item label="Tiền thuê nhà tại Việt Nam">
                        <a-input-number
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            v-model="formData.house_rental"
                            :disabled="disabledField"
                            addon-after="VNĐ"
                            style="width: 100%"
                        />
                    </a-form-model-item>
                    <a-form-model-item label="Khoản đóng bảo hiểm bắt buộc"
                        ><a-input-number
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            v-model="formData.deductible_ins_prem"
                            :disabled="disabledField"
                            addon-after="VNĐ"
                            style="width: 100%"
                        />
                    </a-form-model-item>
                    <a-form-model-item label="Thời điểm trả thu nhập"
                        ><a-date-picker
                            valueFormat="YYYY-MM-DD"
                            v-model="formData.payment_time"
                            :disabled="disabledField"
                            style="width: 100%"
                    /></a-form-model-item>
                    <a-row :gutter="12">
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Người đại diện (NĐD)"
                                    ><a-input
                                        v-model="formData.payment_sign"
                                        :disabled="disabledField"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                        <a-col class="gutter-row" :span="12">
                            <div class="gutter-box">
                                <a-form-model-item label="Chức danh NĐD"
                                    ><a-input
                                        v-model="formData.payment_title"
                                        :disabled="disabledField"
                                /></a-form-model-item>
                            </div>
                        </a-col>
                    </a-row>
                    <a-form-model-item label="Ngày nhập vào hệ thống"
                        ><a-date-picker
                            show-time
                            :value="formData.created"
                            disabled
                            style="width: 100%"
                    /></a-form-model-item>
                </div>
            </a-col>
            <a-col class="gutter-row" :span="24">
                <div
                    style="
                        border-bottom: 1px solid #e8e8e8;
                        margin-bottom: 10px;
                        margin-left: -24px;
                        margin-right: -24px;
                    "
                ></div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box">
                    <span style="display: inline-block; line-height: 32px"
                        >Status:
                        <a-tag
                            :color="
                                formData.status === 'Canceled'
                                    ? 'volcano'
                                    : formData.status === 'Exported'
                                    ? 'blue'
                                    : 'green'
                            "
                            >{{ formData.status }}</a-tag
                        ></span
                    >
                    <a-button
                        type="link"
                        html-type="button"
                        v-if="formData.status !== 'Canceled'"
                        style="color: red"
                        @click="handleTrash"
                        >Move to Trash</a-button
                    >
                    &nbsp;
                    <!-- <a-button type="link" html-type="button" v-if="formData.status === 'Canceled'" style="color: green;" @click="handleActive">Restore this item</a-button> &nbsp;  -->
                </div>
            </a-col>
            <a-col class="gutter-row" :span="12">
                <div class="gutter-box" :style="{ textAlign: 'right' }">
                    <a-button
                        type="secondary"
                        html-type="button"
                        :disabled="disabledField"
                        @click="handleReset"
                        style="margin-right: 8px"
                        >Reset</a-button
                    >
                    <a-button type="primary" html-type="submit" :disabled="disabledField"
                        >Save</a-button
                    >
                </div>
            </a-col>
        </a-row>
    </a-form-model>
</template>
<script>
export default {
    props: {
        receiptID: Number,
        rowKey: Number,
    },
    data: function () {
        return {
            formData: {
                status: null,
            },
        };
    },
    created: function () {
        this.$http.get('/posts/' + this.receiptID).then((response) => {
            this.formData = response.data;
        });
    },
    watch: {
        receiptID: function (val) {
            this.$http.get('/posts/' + val).then((response) => {
                this.formData = response.data;
            });
        },
    },
    computed: {
        formatedID() {
            return new Array(+7 + 1 - (this.receiptID + '').length).join('0') + this.receiptID;
        },
        disabledField() {
            return this.formData.status === 'Active' ? false : true;
        },
    },
    methods: {
        handleTrash: function () {
            this.$http
                .put('/posts/' + this.receiptID, {
                    status: 'Canceled',
                })
                .then((response) => {
                    if (response.data.success) {
                        this.formData.status = 'Canceled';
                        this.$emit('trashed', this.tableItem);
                    }
                });
        },
        handleActive: function () {
            this.$http
                .put('/posts/' + this.receiptID, {
                    status: 'Active',
                })
                .then((response) => {
                    if (response.data.success) {
                        this.formData.status = 'Active';
                        this.$emit('actived', this.tableItem);
                    }
                });
        },
        handleSubmit: function (e) {
            e.preventDefault();

            this.$http.put('/posts/' + this.receiptID, this.formData).then((response) => {
                if (response.data.success) {
                    this.$emit('updated', this.formData);
                }
            });
        },
        handleReset: function () {
            this.$http.get('/posts/' + this.receiptID).then((response) => {
                this.formData = response.data;
            });
        },
    },
};
</script>
<style lang="scss">
.ant-form-item {
    margin-bottom: 0.5rem !important;
}
.ant-form-item-label {
    line-height: inherit !important;

    label {
        font-size: 12px;
        font-weight: bold;

        &:after {
            display: none;
        }
    }
}
.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5) !important;
}
</style>
